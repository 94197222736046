<template>
  <vz-install-app />

  <users-grid />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue';

const UsersGrid = defineAsyncComponent(() => import(/* webpackChunkName: "lobby" */ '@/views/lobby/components/users-grid.vue'));
const VzInstallApp = defineAsyncComponent(() => import(/* webpackChunkName: "lobby" */ '@/shared/components/vz-install-app.vue'));
</script>
