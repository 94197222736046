<template>
  <div
    ref="inputRef"
    class="vz-textarea"
    :class="{ 'vz-textarea--loading': loading, 'vz-textarea--disabled': disabled }"
    :data-errors="validateMessage"
  >
    <label class="text-ellipsis">{{ $t(label) }}</label>

    <div class="vz-textarea__container">
      <textarea
        v-model="vModel"
        :placeholder="$t(placeholder)"
        :disabled="disabled"
        :readonly="readonly"
        :rows="rows"
        :style="{ resize: !resizable ? 'none' : 'vertical' }"
        :aria-label="t('COMPONENT_LABELS.TEXTAREA', { value: ariaLabel || label || placeholder })"
        @input="onUpdate"
        @focus="onFocus"
        @blur="onBlur"
      />
    </div>

    <div :class="['vz-textarea__error', { 'vz-textarea__error--hidden': hideDetails }]" role="alert">
      <p v-if="validateMessage" :class="{ 'vz-textarea__error-internal': !isTouched }">{{ $t(validateMessage) }}</p>
      <p v-else-if="externalError && !isTouched">{{ $t(externalError) }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import { computed, nextTick, type PropType, ref } from 'vue';
import { useValidator } from '@/shared/components/fields/helpers';
import { useTranslator } from '@/plugins/i18n/helpers';
import { scrollToView } from '@shared/helpers';

const t = useTranslator();

const props = defineProps({
  name: { type: String as PropType<string | undefined>, default: undefined },
  modelValue: { type: [Object, Number, String, Array] as PropType<any>, required: true },
  label: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  ariaLabel: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  resizable: { type: Boolean, default: false },
  rows: { type: [Number, String], default: 5 },
  hideDetails: { type: Boolean, default: false },
  errorMessage: { type: String as PropType<string | null | undefined>, default: null },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  fieldErrors: { type: Object as PropType<Record<string, string> | null>, default: null },
});

const emit = defineEmits(['update:model-value', 'input']);

const isFocus = ref<boolean>(false);
const blurTimeout = ref<ReturnType<typeof setTimeout>>();
const inputRef = ref();

const { validateMessage, isTouched } = useValidator(
  computed(() => props.modelValue),
  computed(() => props.rules),
  props.name || props.label
);

const vModel = computed({
  get: (): any => props.modelValue,
  set: (value) => emit('update:model-value', value),
});

const externalError = computed(() => {
  if (props.fieldErrors && props.name && props.fieldErrors[props.name]) {
    return props.fieldErrors[props.name];
  }

  if (!props.errorMessage) {
    return;
  }

  return props.errorMessage;
});

const onUpdate = (ev: Event): void => {
  const { value } = ev.target as HTMLInputElement;
  emit('update:model-value', value);
};

const onFocus = () => {
  if (blurTimeout.value) {
    clearTimeout(blurTimeout.value);
  }

  isFocus.value = true;
  nextTick(() => scrollToView(inputRef.value, { behavior: 'smooth', block: 'center' }));
};

const onBlur = () => (blurTimeout.value = setTimeout(() => (isFocus.value = false), 500));
</script>

<style lang="scss">
.vz-textarea {
  position: relative;
  display: flex;
  flex-direction: column;

  &--loading {
    .vz-textarea__container {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0.125rem;
        left: 0;
        width: 100%;
        height: 0.125rem;
        background-image: linear-gradient(100deg, var(--color-primary-300) 2%, var(--color-primary-900) 44%, var(--color-primary-300) 98%);
        background-repeat: no-repeat;
        background-size: 35% 100%;
        background-position: 0 0;
        animation: skeletonOverlay 2s linear infinite;
      }

      @keyframes skeletonOverlay {
        0% {
          background-position: -100% 0;
        }
        100% {
          background-position: 200% 0;
        }
      }
    }
  }

  &--disabled {
    .vz-textarea__container {
      color: var(--color-disabled);
      background-color: var(--color-background-disabled);
    }
  }

  &__container {
    min-height: 36px !important;
    padding: 8px 8px 8px 8px !important;
    border: var(--border-regular);
    border-radius: var(--border-radius-regular);
    background-color: var(--color-background-regular);
    outline: var(--outline-regular);

    textarea {
      outline: none !important;
      padding-inline-end: 0.5rem;
      width: 100%;
    }

    &:has(textarea:focus) {
      outline: var(--outline-focus);
    }
  }
}
</style>
